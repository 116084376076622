@import './vars.scss';

ui-kit-input {
  display: block;
}

textarea.input {
  height: fit-content;
}

ui-kit-card {
  display: block;
  margin-bottom: $margin-2;
  overflow-x: auto;
}

.button,
.button:hover,
.button:focus {
  border-radius: $border-radius;
  color: $white;
  font-weight: 700;
  text-transform: uppercase;

  &:disabled {
    background-color: $primary-disabled;
    cursor: not-allowed;
  }

  &.is-primary {
    background-color: $primary;
    border-color: $primary;
  }

  &.is-secondary {
    background-color: $gray-light;
    border-color: $gray-light;
    color: $black;
  }

  &.is-danger {
    background-color: $danger;
    border-color: $danger;
    color: $white;
  }
}

.autocomplete-container,
.input {
  border-color: $gray-light;
  border-radius: $border-radius;
  color: $gray-dark;

  &::placeholder {
    color: $gray;
  }
}

.autocomplete-container,
.input,
.select {
  margin-bottom: 12px;
}

/* 
 * We want to style controls in tables without the margin or else the cell's 
 * height will be different and the bottom border will appear offset
 */
table {
  .input,
  .select {
    margin-bottom: 0;
  }
}

button.button:not(:last-of-type),
a.button:not(:last-of-type) {
  margin-right: 9px;
}

.ui-kit-anchor {
  color: $primary;
  font-size: 0.9rem;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $primary;
  }
}

ui-kit-heading {
  display: block;

  &:not(:first-of-type) {
    margin-top: 25px;
  }
}
